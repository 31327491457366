import "./App.css";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import CookiePolicy from "./pages/CookiePolicy";
import DPA from './pages/dpa';
import Logo from './pages/Logo';
import Video from './pages/Video';
import AboutUs from "./pages/AboutUs";
import { Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import FAQ from "./pages/FAQ";
import Pricing from "./pages/Pricing";
//import CookieConsentLib from "./components/CookieConsentLib";
import NewBlogs from "./pages/NewBlogs";
import BlogPostView1 from "./pages/BlogPostsView/BlogPostView1";
import BlogPostView2 from "./pages/BlogPostsView/BlogPostView2";
import BlogPostView3 from "./pages/BlogPostsView/BlogPostView3";
import BlogPostView4 from "./pages/BlogPostsView/BlogPostView4";
import BlogPostView5 from "./pages/BlogPostsView/BlogPostView5";
import BlogPostView6 from "./pages/BlogPostsView/BlogPostView6";
import BlogPostView7 from "./pages/BlogPostsView/BlogPostView7";
import BlogPostView8 from "./pages/BlogPostsView/BlogPostView8";
import BlogPostView9 from "./pages/BlogPostsView/BlogPostView9";
//import SignUp from "./pages/SignUp";
import Unsubscribe from "./pages/Unsubscribe";

import Accountant from "./pages/UseCases/Accountant";
import Logistics from "./pages/UseCases/Logistics";
import Purchase from "./pages/UseCases/Purchase";
import Businessdev from "./pages/UseCases/Businessdev";
import Businessdocuments from "./pages/UseCases/Businessdocuments";
import RAG from "./pages/UseCases/RAG";

import PDFtoxls from "./pages/Converters/pdftoxls";
import PDFtocsv from "./pages/Converters/pdftocsv";
import PDFtable from "./pages/Converters/pdftable";
import BankStatement from "./pages/Converters/bankstatement";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

function App() {
  const location = useLocation();

  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    // Check if the path is 'accountant' or 'unsubscribe'
    if (path === "accounting" || path === "unsubscribe" || path === "logistics" || path === "purchase" || path === "business-development" || path === "business-documents"
    || path === "convert-pdf-to-excel" || path === "convert-pdf-to-csv" || path === "convert-pdf-table" || path === "bank-statement-converter" || path === "business-documents"
    || path === "knowledge-management") {
      setIsSignUp(true);
    } else {
      setIsSignUp(false);
    }

    // Record the page view with Google Analytics
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);  // Ensure the effect runs on changes to pathname or search


  return (
    <div>
      <header className="bg-white sticky top-0 z-50 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
        <div className="relative">{!isSignUp && <Navbar />}</div>
      </header>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route  path="/LandingPage" component={<LandingPage/>} />
        {/* <Route path="/howitworks" element={<ArithmoWorks />} /> 
        <Route path="/howitworks" element={<NewArithmoWorks />} />
        <Route path="/features" element={<Features />} />
        <Route path="/contact" element={<GetInTouch />} />*/}
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/dpa" element={<DPA />} />
        <Route path="/logo" element={<Logo />} />
        <Route path="/video" element={<Video />} />
        {/*<Route path="/imprint" element={<Imprint />} />
        <Route path="/sign-up" element={<SignUp />} />*/}
        <Route path="/faq" element={<FAQ />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<NewBlogs />} />
        <Route path="/blog-unleashing-data-potential" element={<BlogPostView1 />} />
        <Route path="/blog-audit" element={<BlogPostView2 />} />
        <Route path="/blog-forensics" element={<BlogPostView3 />} />
        <Route path="/blog-supply-chain" element={<BlogPostView4 />} />
        <Route path="/blog-financial-planners" element={<BlogPostView5 />} />
        <Route path="/blog-document-management" element={<BlogPostView6 />} />
        <Route path="/7-quick-tips" element={<BlogPostView7 />} />
        <Route path="/start-guide" element={<BlogPostView8 />} />
        <Route path="/blog-finance-accounting" element={<BlogPostView9 />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />

        <Route path="/accounting" element={<Accountant />} />
        <Route path="/logistics" element={<Logistics />} />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/business-development" element={<Businessdev />} />
        <Route path="/business-documents" element={<Businessdocuments />} />

        <Route path="/convert-pdf-to-excel" element={<PDFtoxls />} />
        <Route path="/convert-pdf-to-csv" element={<PDFtocsv />} />
        <Route path="/convert-pdf-table" element={<PDFtable />} />
        <Route path="/bank-statement-converter" element={<BankStatement />} />

        <Route path="/knowledge-management" element={<RAG />} />
      </Routes>
      {/*!isSignUp && <CookieConsentLib />*/}
      {!isSignUp && <Footer />}
    </div>
  );
}

export default App;
