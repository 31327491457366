import React from 'react';
import BlogImage from '../../assets/blog/7Tips.svg';
import { Link } from 'react-router-dom';

const BlogPostView1 = () => {
  return (
    <section className="max-w-6xl mx-auto px-4 py-2 my-8">
      <article>
        <div className="flex flex-col items-center justify-center">
          <div>
            <img src={BlogImage} alt="Blog" width="700px" />
          </div>
          <div className="mt-2">
              <h3 className="my-8 max-w-[700px] text-[32px] text-[black] font-bold leading-10 px-4">
              <span class="text-[#0029ff] font-bold">7 quick Tips</span> to use <a href="https://www.docutize.ai/">docutize.ai</a> like a Pro:
              </h3>
          </div>
          <div className="my-8 max-w-[700px] text-justify text-[18px]">
          <div className="my-4">
                                        <ol style={{ listStyleType: 'decimal'}}>
                                          <p><li style={{ paddingBottom: '20px' }}><span class="text-[#0029ff] font-bold font-bold">Optimize scan quality</span> for better results.</li>
                                          </p><li style={{ paddingBottom: '20px' }}><span class="text-[#0029ff] font-bold">Be precise with GPT inputs</span> to label Text categories. Otherwise GPT might attribute too many values and generate mismatches.</li>
                                          <li style={{ paddingBottom: '20px' }}>When selecting a Text or Table-Column Category for the first time, <span class="text-[#0029ff] font-bold">use the Checkbox</span>. Once a category was already selected you will find it in the Dropdown menu. For  <span class="text-[#0029ff] font-bold">similar labels</span> please use the existing categories in the  <span class="text-[#0029ff] font-bold">Dropdown menu</span>. Otherwise, values might not be correctly assigned.</li>
                                          <li style={{ paddingBottom: '20px' }}><span class="text-[#0029ff] font-bold">Use the Preview</span> to see the current selections and the structured data.</li>
                                          <li style={{ paddingBottom: '20px' }}>If you need more advanced download options, go to <span class="text-[#0029ff] font-bold">Download Settings</span>. There you can <span class="text-[#0029ff] font-bold">save </span> all made <span class="text-[#0029ff] font-bold">selections and reuse</span> them with similar documents.</li>
                                          <li style={{ paddingBottom: '20px' }}>Automated Document Extraction works best with <span class="text-[#0029ff] font-bold">clear formatted PDFs</span>. However, docutize.ai allows for <span class="text-[#0029ff] font-bold">manual corrections</span> of unclear formats. </li>
                                          <li style={{ paddingBottom: '20px' }}><span class="text-[#0029ff] font-bold">Edit the Text & Table frames</span> in the document easily in Edit mode if they were identified incorrectly.</li>
                                        </ol>
                                        </div>
                                        <div className="my-4 pt-10 pb-10">
                                        <h1 class="font-bold pb-10">If you have any <span class="text-[#0029ff] font-bold">questions</span>, reach us here:</h1>
                                        <ul style={{ listStyleType: 'disc' }}>
                                          <li style={{ paddingBottom: '20px', color: '#0029FF', fontWeight: 'bold'}}>In-app Chat</li>
                                          <li style={{ paddingBottom: '20px' }}>Email to <a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="mailto:support@docutize.ai">support@docutize.ai</a></li>
                                          {/*<li style={{ paddingBottom: '20px' }}><a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="https://calendly.com/docutizeai/30min">Schedule a meeting</a></li>*/}
                                          <li style={{ paddingBottom: '20px' }}>Watch our <a style={{color: '#0029FF', textDecoration: 'Underline', fontWeight: 'bold'}} href="https://www.youtube.com/watch?v=vJsQs3zVyaM5">5min Tutorial</a> to see all core features in action</li>
                                        </ul>
                                        </div>
          <div className="my-4 pb-20">
          <p className="font-bold">
          Start Data Extraction now with <a style={{color: '#0029FF', textDecoration: 'Underline'}} href="https://app.docutize.ai/sign-in">docutize.ai</a>
          </p>
          </div>
      </div>
        </div>
      </article>
 
    </section>
  );
};

export default BlogPostView1;
