import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useGoogleAnalyticsTracker from "../utils/customHooks/googleAnalytics";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Hero_img from '../assets/Hero.png';

const Hero = () => {
  const [isMuted, setIsMuted] = useState(true); // State to track whether the video is muted
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null); // Reference to the video element


  const navigate = useNavigate();

  const googleAnalyticsTracker = useGoogleAnalyticsTracker("Hero");

  const onClickHandler = (e) => {
    googleAnalyticsTracker("Sign up", "hero start free sign up");
    navigate("sign-up");
  };

  return (
    <section id="home" className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl   m-auto px-4 py-0 md:pb-20 pb-10">
      <div className="flex flex-col justify-between items-center flex-nowrap gap-2">
        <div className="lg:text-center text-left w-[100%]">
          <h2 className="mb-[8px] xl:mb-[8px] lg:mb-[6px] md:mb-[2px] font-semibold tracking-wider text-[#0029ff] text-[16px] ">
            #1 EASIEST AI DATA EXTRACTION PLATFORM
          </h2>
          <h2 className="mb-[8px] xl:mb-[8px] lg:mb-[6px] md:mb-[2px] font-medium text-[#2c374f] lg:text-[38px] text-[30px]">
            Extract Data from Business Documents
          </h2>
          <p className="font-normal text-left lg:text-center text-[#2c374f] text-[17px] xl:text-[19px] lg:text-[19px]  mx-auto lg:max-w-[550px] ">
          Save time by Automating Manual Data Extraction from any Business PDF into structured .xls, .csv or .txt files in just a few clicks. No more repetitive Manual Data Entry.
          </p>
          <div className="flex flex-col justify-center items-center mx-auto my-[30px] xl:my-[30px] lg:my-[28px] md:my-[10px]">
            <a className="w-[100%] md:w-[350px] text-center" href="https://app.docutize.ai/sign-in">
              <button className="w-[100%] flex justify-center items-center transition duration-150 ease-in-out text-[15px] mt-5 md:text-[20px] py-[10px] md:py-[10px] px-[30px] bg-[#0029ff] text-white font-semibold rounded-lg">
                Start Free
                <span className="ml-2">
                  <TrendingFlatIcon />
                </span>
              </button>{/*onClick={onClickHandler}*/}
            </a>
            <span className="p-2 text-gray-400 font-base text-[12px] xl:text-[16px] lg:text-[14px] md:text-[12px] md:ml-[0px]">
              No credit card or setup required.
            </span>
          </div>
        </div>
        {/* video */}
        <div className="w-[100%] relative frame-container w-full shadow-xl border border-slate-200 p-2 rounded-xl bg-[#0039FF]">
        <img className='w-[100%]' src={Hero_img} alt="Hero_image" />
          {/*<video
            autoPlay
            muted={isMuted} //Set the muted property based on the state
            loop
            id="video"
            playsInline
            ref={videoRef} // Set the reference to the video element
          >
            <source src={HeroVideo} type="video/mp4" />
  </video>*/}
        </div>
      </div>
    </section >
  );
};

export default Hero;
