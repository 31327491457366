import React from 'react';
import BlogImage from '../../assets/blog/Audit.png';

import { Link } from 'react-router-dom';

const SingleBlog2 = () => {
  return (
    <article className="max-w-[300px] m-4 mt-[24px]">
      <div>
      <Link to="/blog-audit">
        <img src={BlogImage} alt="Blog" width={300} />
        </Link>
      </div>
      <div className="mt-4">
        <Link to="/blog-audit">
          <h3 className="text-[18px] font-medium leading-6">
          Elevating Audit Efficiency with AI Data Extraction
          </h3>
        </Link>
      </div>
      {/*<div>
        <span className="text-[#111] text-[10px]">January 10, 2023</span>
      </div>*/}
    </article>
  );
};

export default SingleBlog2;
