import React from 'react';
import Tabs from './Tabs';
const UseCases = () => {
  return (
    <section id='usecases' className='pb-5 lg:pb-10'>
      <div className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl max-w-6xl  m-auto px-4 py-8">
        <h2 className="my-[8px] font-semibold text-center text-[#0029ff]  text-[16px]">
          USE CASES
        </h2>
        <h1 className="mb-[50px] font-medium text-center text-slate-800  md:text-[24px]  text-[22px]">
        Business Documents from all Industries
        </h1>
        <Tabs />
      </div>
    </section>
  );
};

export default UseCases;
