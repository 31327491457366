import React from 'react';
import HeroVideo from "../assets/HeroVideo/video2.mp4"

const NewArithmoWorks = () => {
  return (
    <video
          autoPlay
          muted
          loop
          fluid={false} 
          id="video"
          width={1500}
          
          playsInline
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>

  );
};

export default NewArithmoWorks;
