import React from 'react';
import Docutize_logo from '../assets/docutize_logo.png';
import Docutize_banner from '../assets/docutize_banner.png';

const NewArithmoWorks = () => {
  return (
    <section>
    <div>
      <img
                    src={Docutize_logo}
                    alt="docutize_logo"
                    width={800}
                  />
    </div>
    <div>
    <img
                    src={Docutize_banner}
                    alt="docutize_banner"
                    width={1500}
                  />
    </div>
   </section> 

  );
};

export default NewArithmoWorks;
