import React from 'react';
import SingleBlog1 from '../components/SingleBlog/SingleBlog1';
import SingleBlog2 from '../components/SingleBlog/SingleBlog2';
import SingleBlog3 from '../components/SingleBlog/SingleBlog3';
import SingleBlog4 from '../components/SingleBlog/SingleBlog4';
import SingleBlog5 from '../components/SingleBlog/SingleBlog5';
import SingleBlog6 from '../components/SingleBlog/SingleBlog6';
import SingleBlog7 from '../components/SingleBlog/SingleBlog7';
import SingleBlog8 from '../components/SingleBlog/SingleBlog8';
import SingleBlog9 from '../components/SingleBlog/SingleBlog9';

const NewBlogs = () => {
  return (
    <section className="max-w-7xl mx-auto px-4 py-2 my-0">
      <h2 className="mb-6 mt-8 xl:text-[34px] lg:text-[30px] md:text-[26px] text-[30px] text-center text-[black] font-medium">
        Blog
      </h2>
      <div className="flex items-center justify-center flex-wrap pb-20">
        <SingleBlog8 />
        <SingleBlog7 />
        <SingleBlog9 />
        <SingleBlog1 />
        <SingleBlog6 />
        <SingleBlog3 />
        <SingleBlog2 />
        <SingleBlog5 />
        <SingleBlog4 />
      </div>
    </section>
  );
};

export default NewBlogs;
