import React from 'react'
import picture from '../assets/ready.svg'

const TryAIGenerator = () => {
    return (
        <div className='xl:max-w-6xl lg:max-w-4xl md:max-w-2xl m-auto px-4 py-0 relative'>
            <div className="lg:flex justify-between items-baseline">
                <div className='lg:w-1/2 lg:pr-[50px] text-white'>
                    <h1 className='font-bold text-[24px] md:text-[28px] lg:text-[30x] mb-6'>
                        Ready to start with AI Data Extraction?
                    </h1>
                    <p className='font-medium'>Sign up free and get started using docutize.ai, with full access to all features.</p>
                    {/*<div className="mt-10 flex gap-3">
                    <a href='https://app.docutize.ai/sign-up'><button className='bg-white text-[14px] font-semibold text-black px-4 py-3 rounded-lg'>Start now</button></a>
                        <a href='https://calendly.com/docutizeai/30min' target="_blank"><button className='bg-transparent hover:bg-[#002577] border border-white text-[14px] font-semibold text-white px-4 py-3 rounded-lg'>Book a Demo</button></a>
                    </div>*/}
                </div>
                <div className="lg:w-1/2">
                    <img className='hidden lg:block lg:absolute lg:mt-0 mt-10 -right-7-0 -top-20 w-[100%] lg:pt-10' src={picture} alt="docs" />
                </div>
            </div>
        </div>

    )
}

export default TryAIGenerator
