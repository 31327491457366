import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const FrequentlyAskedQuestion = () => {
    const [openState, setOpenState] = useState(false);
    const [openState1, setOpenState1] = useState(false);
    const [openState2, setOpenState2] = useState(false);
    const [openState3, setOpenState3] = useState(false);
    const [openState4, setOpenState4] = useState(false);

    const toggleOpenState = () => {
        setOpenState(!openState)
    };
    const toggleOpenState1 = () => {
        setOpenState1(!openState1)
    };
    const toggleOpenState2 = () => {
        setOpenState2(!openState2)
    };
    const toggleOpenState3 = () => {
        setOpenState3(!openState3)
    };
    const toggleOpenState4 = () => {
        setOpenState4(!openState4)
    };
    return (
        <section id='faq'>
        <div className='faq xl:max-w-6xl lg:max-w-4xl md:max-w-2xl mb-28 m-auto px-4 py-0'>
            <div className="lg:flex justify-between items-baseline">
                <div className='lg:w-1/2 pr-[50px]'>
                    <h4 className='text-[#0029ff] font-semibold text-[16px]'>FAQs</h4>
                    <h1 className='text-slate-800 font-medium mb-5 md:text-[24px]  text-[22px] '>Frequently asked questions</h1>
                    <a className='text-[#0029ff] font-medium link' href="https://www.docutize.ai/faq">See More <ArrowRightAltIcon /> </a>
                </div>
                <div className="lg:w-1/2">
                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState} className='list-none flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        What is docutize.ai?
                            {openState ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        docutize.ai is a web-based service to extract data from any PDF document. docutize.ai is powered by AI & GPT to identify all Tables, Text and Key Information. Select the Data you need to Extract. Next docutize.ai will Auto-Select all following pages. Validate the Auto-Selection and Download the Results as .xls, .csv or .txt file.
                        </p>
                    </details>
                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState1} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        What kind of data is docutize.ai for?
                            {openState1 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        The Free & Pro Plans are designed to work for every PDF document containing machine written Text & Numbers. Enterprise Plans can include any kind of automatic identification up to your needs.
                        </p>
                    </details>

                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState2} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        Does docutize.ai support handwriting?
                            {openState2 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        Handwriting is not included at this point for Free & Pro Plans. Enterprise Plans can include any kind of automatic identification up to your needs.
                        </p>
                    </details>

                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState3} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        Do scanned PDF documents work too?
                            {openState3 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        Yes, docutize.ai can process scanned PDF formats as well. However, if the scan quality is really low there can be typos as Text & Numbers can not be identified correctly due to the bad quality of the image.
                        </p>
                    </details>

                    <details className='text-[18px]'>
                        <summary onClick={toggleOpenState4} className='list-none  flex justify-between items-center mt-6 gap-[10px] cursor-pointer text-left font-medium text-lg text-slate-800'>
                        How do I get started?
                            {openState4 ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                        </summary>
                        <p className='text-slate-800 font-normal text-[16px] tracking-wide mt-4'>
                        Just sign up, choose your plan and upload documents. You will be guided each step of the process of extracting data by our platform.
                        </p>
                    </details>
                </div>
            </div>
        </div>
        </section>
    )
}

export default FrequentlyAskedQuestion
