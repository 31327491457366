import { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import loopingWords from "../constants/loopWords";
import Button from './Button';
import { Link } from 'react-router-dom';


const Inputsignup = () => {

  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);



  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      1200
    );
    return () => clearTimeout(intervalId);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

const handleSubmit = (event) => {
  event.preventDefault();
  if (isValidEmail) {
    localStorage.setItem("savedEmail", email);
    const redirectUrl = `https://app.docutize.ai/sign-up/?email=${encodeURIComponent(email)}`;
    window.location.href = redirectUrl;
  }
};
 
  return (
    
      <div className="flex flex-col items-center justify-center mb-14 py-8 gap-4 2xl:mb-2 text-center rounded-xl bg-[#F1F3FA] shadow-xl border border-slate-200">
        <div><h3 className="font-medium  xl:text-[34px] lg:text-[30px] md:text-[26px] text-[30px]">Join happy users around the world</h3></div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-5">
          <div className="xl:text-[20px] lg:text-[18px] md:text-[16px] text-[20px] font-medium">Start now  <span className="text-[#0029FF] ">— It's free!</span></div>
          <form onSubmit={handleSubmit}>
            <div className="flex gap-4 items-center sm:flex-row flex-col">
              
            <input
            type="email"
            placeholder="Your Work Email*"
            className="px-2 border-2 border-[#e6e7ec] md:py-2 py-1 md:max-w-[300px] rounded-md"
            value={email}
            onChange={handleEmailChange}
          />
          <a href={isValidEmail ? "https://app.docutize.ai/sign-up" : null}>{/*for just Button change to just link*/}
            <Button text="Get Started" />
          </a>
            </div>
          </form>
          
        </div>
        <span className="p-2 text-gray-400 font-base text-[12px] xl:text-[16px] lg:text-[14px] md:text-[12px] md:ml-[0px]">
              No credit card or setup required.
            </span>
      </div>
    
  )
}

export default Inputsignup;