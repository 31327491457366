import { useEffect, useState } from "react";
import Button from "../components/Button";
import { EMAIL_REGEX } from "../constants/regex";
import requestHandler from "../utils/request/requestHandler";
import { useLocation } from 'react-router-dom';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();

  const onEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  useEffect(() => {
    const getEmailFromURL = () => {
      const searchParams = new URLSearchParams(location.search);
      const emailParam = searchParams.get('email');
      if (emailParam) {
        setEmail(emailParam);
      }
    };

    getEmailFromURL();
  }, [location.search]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onClickHandler = async (e) => {
    e.preventDefault();
    if (EMAIL_REGEX.test(email)) {
      setEmailValid(true);
      setLoading(true);
      const results = await requestHandler({
        url: "https://api.getwaitlist.com/api/v1/waiter",
        requestOptions: {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        },
        data: {
          email,
          api_key: process.env.REACT_APP_WAITLIST_KEY,
        },
      });
      if (results) {
        if (results.priority) setSuccess(true);
      }
      setLoading(false);
    } else {
      setEmailValid(false);
    }
  };

  return (
    <div className="flex align-center justify-center items-center p-auto m-auto">
      <div className="w-screen flex justify-center items-center">
        <form
          className="p-10 2xl:w-1/4 xl:w-1/3 md:w-1/2 xs:w-screen  bg-white rounded-xl space-y-5 mt-16"
          onSubmit={onClickHandler}
        >
          <h1 className="text-center font-bold text-3xl text-[#0029FF]">
            <a href="https://www.docutize.ai/">docutize.ai</a>
          </h1>
          <h2 className="text-left text-2xl font-bold">Unsubscribe Email</h2>
          <div className="flex flex-col space-y-2">
          <label className="text-sm font-light">Email</label>
            <input
              onChange={onEmailChange}
              className="w-auto px-3 py-2  border border-slate-400"
              style={{ borderRadius: '5px' }}
              type="email"
              placeholder="Your Email"
              value={email}
              name="email"
              id="email"
            />
            {!emailValid && (
              <p className="text-left text-xs  text-[#ff0000]">
                Enter a valid Email
              </p>
            )}
          </div>
          <div className="flex justify-center align-center">
            <Button type="submit" isLoading={loading} text="Unsubscribe" />
          </div>
          {success && (
            <div
              className="bg-teal-100 rounded-b text-teal-900 px-4 py-3"
              role="alert"
            >
              <div className="flex center">
                <div>
                  <p className="text-left text-xs">
                  {email} has been unsubscribed!
                  </p>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Unsubscribe;
