import React, { useState } from 'react';
import uploadVid from '../assets/videos/upload.mp4';
import selectVid from '../assets/videos/select.mp4';
import downloadVid from '../assets/videos/download.mp4';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const TabItem = ({ title, icon, activeTab, index, changeTab }) => {
    const isActive = activeTab === index;

    return (
        <button
            className={`${isActive ? 'bg-[#E8F0FF] rounded-xl' : 'bg-transparent'
                } flex justify-between items-center p-3 focus:outline-none`}
            onClick={() => changeTab(index)}
        >

            <span className={`${isActive ? 'text-[#0029ff]' : 'border-transparent'
                } text-[16px] font-semibold `}>{title}</span>
        </button>
    );
};

const HowItWorksTabs = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [menuItems, setMenuItems] = useState(false);
    const [selectedTabTitle, setSelectedTabTitle] = useState("1. Upload PDFs & GPT Input");


    const changeTab = (index, title) => {
        setActiveTab(index);
        setSelectedTabTitle(title);
        setMenuItems(false);
    };

    const showDropdDownMenu = () => {
        setMenuItems(!menuItems);
    };

    return (
        <div className="tabs mt-5">
            <div className="lg:hidden   mobile-tabs">
                <div
                    className="flex justify-between relative p-3 border border-gray-300 rounded-md bg-white shadow-sm focus:border-blue-500"
                    onClick={showDropdDownMenu}
                    value={activeTab}
                >
                    {selectedTabTitle}
                    <ArrowDropDownIcon />
                </div>
                {menuItems &&
                    <div className=" flex-col bg-white border shadow-lg rounded-lg justify-between gap-3">
                        <TabItem title="1. Upload PDFs & GPT Input" activeTab={activeTab} index={0} changeTab={(index, title) => changeTab(index, "1. Upload PDFs & GPT Input",)} />
                        <TabItem title="2. Select Text & Tables" activeTab={activeTab} index={1} changeTab={(index, title) => changeTab(index, "2. Select Text & Tables",)} />
                        <TabItem title="3. Preview & Download" activeTab={activeTab} index={2} changeTab={(index, title) => changeTab(index, "3. Preview & Download",)} />
                    </div>
                }
            </div>

            <div className="hidden lg:flex gap-3 border border-slate-300 rounded-xl p-1 justify-center w-fit mx-auto no-scrollbar overflow-scroll">
                <TabItem title="1. Upload PDFs & GPT Input" activeTab={activeTab} index={0} changeTab={(index, title) => changeTab(index, "1. Upload PDFs & GPT Input",)} />
                <TabItem title="2. Select Text & Tables" activeTab={activeTab} index={1} changeTab={(index, title) => changeTab(index, "2. Select Text & Tables",)} />
                <TabItem title="3. Preview & Download" activeTab={activeTab} index={2} changeTab={(index, title) => changeTab(index, "3. Preview & Download",)} />
            </div>

            <div className="mt-8 bullets">
                {activeTab === 0 &&
                    <div>
                        <div className='mt-2 flex flex-col justify-between items-center gap-10'>
                            <div className='text-[#2C374F] text-left'>
                                <h2 className='font-normal text-[16px] max-w-[800px]'>
                                    Select the documents language and upload multiple business PDF files with up to 100 Pages. If you are looking for values inside of text paragraphs, you can optionally use GPT to identify those. E.g. all Order numbers in a document.
                                </h2>
                            </div>
                            <div className="bg-[white] frame-container w-full shadow-xl border border-slate-200 p-2 rounded-xl">
                                <video
                                    className='w-full'
                                    autoPlay
                                    loop
                                    id="video"
                                    playsInline

                                >
                                    <source src={uploadVid} type="video/mp4" />
                                </video>
                            </div>
                        </div>

                    </div>
                }
                {activeTab === 1 &&
                    <div>
                        <div className='mt-2 flex flex-col justify-between items-center gap-10'>
                            <div className='text-[#2C374F] text-left'>
                                <h2 className='font-normal text-[16px] max-w-[800px]'>
                                Select Text values by checking the box in the top right section. Selecting Table-Columns works the same but in the bottom right section. Following Pages will be auto-selected.
                                </h2>

                            </div>
                            <div className="bg-[white] frame-container w-full  shadow-xl border border-slate-200 p-2 rounded-xl">
                                <video
                                    className='w-full'
                                    autoPlay
                                    loop
                                    id="video"
                                    playsInline

                                >
                                    <source src={selectVid} type="video/mp4" />
                                </video>
                            </div>
                        </div>

                    </div>
                }
                {activeTab === 2 &&
                    <div>
                        <div className='mt-2 flex flex-col justify-between items-center gap-10'>
                            <div className='text-[#2C374F] text-left'>
                                <h2 className='font-normal text-[16px] max-w-[800px]'>
                                Open the Preview to see the current selection and organization of the data. Then Download in a structured format as .xls, .csv or .txt file.
                                </h2>
                            </div>

                            <div className="bg-[white] frame-container w-full shadow-xl border border-slate-200 p-2 rounded-xl">
                                <video
                                    className='w-full'
                                    autoPlay
                                    loop
                                    id="video"
                                    playsInline

                                >
                                    <source src={downloadVid} type="video/mp4" />
                                </video>
                            </div>
                        </div>

                    </div>
                }

            </div>
        </div>
    );
};

export default HowItWorksTabs
