import React from 'react';


import Inputsignup from "../../components/Inputsignin";
import Footer from "../../components/Footer";

import FAQshort from "../../components/FAQshort";
import Features from "../../components/Features";
import checkicon from '../../assets/check.svg'
import Finance from '../../assets/Hero.png';
import user1 from '../../assets/Pers1.png';

const PDFtable = () => {
  return (
    <section>
      
      
      <div className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl max-w-6xl  m-auto px-4 py-8">
      <div className="pb-5 border-b-2"><a href="/">
                  <img className="w-[150px]" src="/logo.svg" alt="logo" />
                  </a>
                  </div>
      <div className="mt-8 bullets ">
                                   <div>
                        <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                            <h2 className="mb-[8px] xl:mb-[8px] lg:mb-[6px] md:mb-[2px] font-semibold tracking-wider text-[#0029ff] text-[16px] ">
            #1 EASIEST AI DATA EXTRACTION PLATFORM
          </h2>
                                <h2 className='font-medium text-[40px]'>
                                Convert PDF Tables to Excel
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Extract PDF table into excel with the most advanced table converter. Besides converting PDF table excel our AI Software also organizes the data for you.
                                </p>
                                
                            </div>
                            <div className='lg:w-[70%] mt-0 '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Finance} alt="finance" />
                                </div>
                            </div>
                        </div>
                        <div className="xl:pt-20 pt-10"><Inputsignup /></div>
                        
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                            "We convert a pdf table to excel with ease using docutize.ai. It's the ideal table from pdf to excel tool as we can use it for almost all different documents for pdf table to xls."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user1} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Lisa B.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Accounting Manager
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>

      <div className=" lg:pt-15 mb-12 ">
        <Features />
      </div>
      <div className="faq lg:pt-18 mb-12 ">
        <FAQshort />
      </div>
      <Footer />

      {/*<div className="bg-gradient-to-r from-teal-600 via-purple-800 to-pink-700 py-28 lg:pt-18 overflow-hidden">
        <TryAIGenerator />
      </div>*/}
    </section>
  );
};

export default PDFtable;
