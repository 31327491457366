import React, { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { PricingCard, PricingCardProps, PricingFeature } from './PricingCard/PricingCard'
import { IOSSwitch } from './PeriodToggler/PeriodToggler';
import './PricingPage.css'
// import { FAQ } from 'components/modal/FAQ';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1440,
    },
};
const theme = createTheme({ breakpoints });

type PricingPlanType = "Free" | "Pro" | "Enterprise"

interface PricingPlan extends PricingCardProps {
    planType: PricingPlanType
}

interface PlanFeature extends PricingFeature {
    plans: PricingPlanType[];
}

const yearlySavePercentage = 35

interface PendingAction {
    actions: string[]
    loading: boolean
}

interface ProPlanFeature {
    id: number
    name: string
    description: string
    interval: [{
        billing_interval: "monthly" | "yearly"
        currency: string
        id: number
        maximum_pages: number
        plan_id: number
        price: number
        stripe_price_id: string
    }[]]
    stripe_product_id: string
}

export const pricingPlanFeaturesOther: PlanFeature[] = [
    { title: "20 Pages / month", plans: ["Free"], available: true },
    { title: "OCR, AI Table & key-value-pair, GPT Recognition", plans: ["Free", "Pro"], available: true },
    { title: "Self Set-up", plans: ["Free", "Pro"], available: true },
    { title: "Support", plans: ["Pro"], available: true },
    { title: "Custom Pages / month", plans: ["Enterprise"], available: true },
    { title: "Custom Recognition", plans: ["Enterprise"], available: true },
    { title: "Full Set up", plans: ["Enterprise"], available: true },
    { title: "Premium Support", plans: ["Enterprise"], available: true },
    { title: "Full Document Extraction Service", plans: ["Enterprise"], available: true },
]

const getPlanFeatures = (planType: PricingPlanType, pricingPlanFeatures: PlanFeature[]): PricingFeature[] => {
    const features: PricingFeature[] = []
    pricingPlanFeatures.forEach(({ plans, ...others }) => {
        if (plans.includes(planType)) {
            features.push({ ...others })
        }
    })
    return features
}

export const PricingPage = () => {

    const [pricingPlanFeatures, setPricingPlanFeatures] = useState<PlanFeature[]>([])
    const [isMonthly, setIsMonthly] = useState(false)
    const [proPlanFeatures, setProPlanFeatures] = useState<ProPlanFeature[]>([])

    const getPricingPlans = async () => {
        try {
            const url = `${API_BASE_URL}/subscriptions/get-subscription-data`
            const response = await fetch(url);
            const data = await response.json();
            setProPlanFeatures(data.data.plans)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getPricingPlans()
    }, [])

    const pricingPlans: PricingPlan[] = [
        {
            title: "Free",
            monthlyPrice: 0,
            yearlyPrice: 0,
            description: "For Individuals & to try out the platform's features",
            features: getPlanFeatures("Free", pricingPlanFeatures),
            planType: "Free",
            buttonText: "Get Started",
            hideBillingPeriod: true,
            onClick: () => window.open("https://app.docutize.ai/sign-in", "_self"),
        },
        {
            title: "Pro",
            description: "For Businesses with some data extraction need",
            monthlyPrice: 10,
            yearlyPrice: 12.99,
            onClick: () => window.open("https://app.docutize.ai/sign-in", "_self"),
            features: getPlanFeatures("Pro", pricingPlanFeatures),
            planType: "Pro",
            buttonText: "Get Started",
            background: "rgba(241, 243, 250, 1)",
            btnClassName: "custom-subs-btn",
        },
        {
            title: "Enterprise",
            description: "For Enterprises with large data extraction need",
            monthlyPrice: 10,
            yearlyPrice: 8,
            onClick: () => window.open("mailto:support@docutize.ai", "_blank"),
            features: getPlanFeatures("Enterprise", pricingPlanFeatures),
            planType: "Enterprise",
            buttonText: "Email us",
            customPrice: "Custom",
            customBillingPeriodText: "yearly billing only",
            hideSymbol: true
        },
    ]

    useEffect(() => {
        const features: PlanFeature[] = []
        proPlanFeatures?.length > 0 && proPlanFeatures.forEach((proPlanFeature) => {
            const feature = proPlanFeature.interval[0]
                .filter(interval => (isMonthly && interval.billing_interval === "monthly") || (!isMonthly && interval.billing_interval === "yearly"))
            feature.forEach((interval) => {
                features.push({
                    title: `${interval.maximum_pages.toLocaleString()} Pages / ${isMonthly ? "month" : "year"}`,
                    plans: ["Pro"],
                    attributes: {
                        type: 'radio',
                        price: interval.price,
                        onClick: () => {
                            window.open("https://app.docutize.ai/sign-in", "_self")
                        },
                        radioGroupId: "pages_" + isMonthly ? "monthly" : "yearly"
                    },
                    available: true
                })
            })
        })
        setPricingPlanFeatures([...pricingPlanFeaturesOther, ...features])
    }, [proPlanFeatures, isMonthly])

    return (
        <div id='pricing' className='pricing-page'>

            <div className="pricing-heading">
                Pricing
            </div>

            <div className='period-toggler-container'>
                <div className={`billing-period-text mr-3`}>Billed Monthly</div>
                <div>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={<IOSSwitch onChange={() => setIsMonthly(!isMonthly)} defaultChecked checked={!isMonthly} />}
                        label=""
                    />
                </div>
                <div className={`billing-period-text ml-3 ${!isMonthly && 'active'}`}>Billed Yearly (Save up to {yearlySavePercentage}%)</div>
            </div>

            <div className='pricing-cards-container' >
                <Grid container spacing={2} justifyContent="center">
                    {pricingPlans.map((plan, index) => {
                        const mdValue = index === 1 ? 4 : 3.8; // Assign md=4 for the middle container, md=3 for the others
                        return (
                            <ThemeProvider theme={theme} key={`pricing-plan-${index}`}>
                                <Grid item xs={12} md={mdValue} display={'flex'} justifyContent={"center"}>
                                    <PricingCard
                                        btnClassName={plan.btnClassName}
                                        background={plan.background}
                                        customBillingPeriodText={plan.customBillingPeriodText}
                                        hideBillingPeriod={plan.hideBillingPeriod}
                                        customPrice={plan.customPrice}
                                        buttonText={plan.buttonText}
                                        description={plan.description}
                                        features={plan.features}
                                        monthlyPrice={plan.monthlyPrice}
                                        title={plan.title}
                                        yearlyPrice={plan.yearlyPrice}
                                        mode={isMonthly ? "monthly" : "yearly"}
                                        onClick={plan.onClick}
                                        hideSymbol={plan.hideSymbol}
                                        isBtnLoading={plan.isBtnLoading}
                                    />
                                </Grid>
                            </ThemeProvider>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}
