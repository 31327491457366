import React from 'react'
// import CheckIcon from '@mui/icons-material/Check';
import checkicon from '../assets/check.svg'



const Features = () => {
  return (
        <div id='features' className='safety xl:max-w-6xl lg:max-w-4xl md:max-w-2xl m-auto px-4 py-0 lg:pt-10'>
            <div className="flex flex-col">
                <div className='text-center mb-10' >
                    <h4 className='my-[8px] font-semibold text-center text-[#0029ff]  text-[16px]'>FEATURES</h4>
                    <h1 className='mb-[15px] font-medium text-center text-slate-800  md:text-[24px]  text-[22px]'>Advanced AI Platform to help save time</h1>
            </div>
                <div className="features px-5 py-4 lg:px-12 lg:py-10 rounded-xl bg-[#F1F3FA] shadow-xl border border-slate-200">
                    <div className='lg:flex mt-5 gap-10'>
                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />
                                Free
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            Sign Up and Start Free without any hidden costs. No credit card or Installation required. docutize.ai runs in your Browser.
                            </p>
          </div>

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                AI Recognition
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            docutize.ai is automatically recognising Tables and key-value-pairs (E.g. Name: John) by using AI.
                            </p>
          </div>

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                GPT-powered
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            GPT Large Language Models can identify specific values from Texts (e.g. all order numbers in your documents).
                            </p>
            </div>
          </div>
                    <div className="lg:flex lg:mt-5 gap-10">

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                Easy to use
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            No technical knowledge required so everybody can use it. You can at any point change selections, edit, etc. You decide which data you want to extract.
                            </p>
                        </div >

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                OCR Engine
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            Optical Character Recognition (OCR) is a technology used to get machine printed Letters and Numbers from scanned documents.
                            </p>
          </div>

                        <div className="mb-5 md:mb-3 w-full lg:w-1/3">
                            <h4 className='font-semibold flex gap-2 mb-2 text-slate-800'>
                                <img src={checkicon} alt="" />

                                GDPR Compliant
                            </h4>
                            <p className='font-normal pl-2 text-[16px] text-slate-700'>
                            Your data's safety is our priority. We are using secure data transmission & trusted cloud providers to ensure the European Union GDPR.
                            </p>
            </div>
          </div>
            </div>
            </div>
          </div>
    )
}

export default Features;
