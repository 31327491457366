import React from 'react';
import { useLocation } from 'react-router-dom';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TryAIGenerator from "../components/TryAIGenerator";

const Footer = () => {
  const location = useLocation();
  return (
    
      
    
    <footer className="bg-[#F1F3FA] ">
      <div className="bg-gradient-to-r from-teal-600 via-purple-800 to-pink-700 py-28 lg:pt-18 overflow-hidden">
        <TryAIGenerator />
      </div>
      <article className="mx-auto max-w-6xl">
        <div className="flex flex-col justify-between">
          <article>
            <div className="p-8 flex flex-col justify-between items-start flex-wrap md:flex-row xl:text-[17px] lg:text-[16px] md:text-[15px] ">
              <div className="text-slate-700  lg:w-2/6">
                <div>
                  <a href="/">
                    <img className="w-[180px]" src="/logo.svg" alt="logo" />
                  </a>
                </div>
                <ul className='my-4'>
                  <li className="mt-4 text-[14px]  text-slate-700 font-medium">
                    Our <span className='text-[#0029ff]'>AI Data Extraction Platform</span> enables everyone to extract data in minutes.
                  </li>
                  <li className=" mt-4 text-[14px] text-slate-700 font-medium">
                    Using AI, we’re radically changing the process of data extraction, making it scalable and affordable.
                  </li>
                </ul>
                {/* <div className=" my-7 flex justify-evenly items-center gap-6">
                  <a href="/">
                    <img src="https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/63c908d4cf6b6c9275f131f9_21972-312_SOC_NonCPA.png" loading="lazy" alt="SOC2 compliant" className='w-[50px]' />
                  </a>
                  <a href="/">
                    <img src="https://assets-global.website-files.com/61dc0796f359b6145bc06ea6/64e87563006ccc7100d8304a_logo-cai%201.svg" loading="lazy" alt="" className='w-[100px]' />
                  </a>
                </div> */}
                {/* <p className=" mt-3 text-[13px] text-slate-700 font-light">Address.</p> */}
              </div>
              {/* Col 1 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                  <h2 className="font-semibold text-[#0029ff]">RESOURCES</h2>
                </div>
                <ul>
                <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/">Home</a>
              </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/pricing">Pricing</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/blog">Blog</a>
                  </li>
                  {/*<li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/faq">FAQs</a>
                  </li>*/}
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://app.docutize.ai/sign-in">Sign in</a>
                  </li>
                  {/*<li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/#usecases">Use Cases</a>
                  </li>*/}
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/#howitworks">How it works</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/#features">Features</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/knowledge-management">AI Chatbot</a>
                  </li>
                  
                </ul>
              </div>

              {/* Col 2 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                  <h2 className="font-semibold text-[#0029ff]">HELP</h2>
                </div>
                <ul>
                <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://www.docutize.ai/faq'>FAQs</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='/7-quick-tips'>7 quick Tips</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='/start-guide'>Start Guide</a>
                  </li>

                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://www.youtube.com/watch?v=vJsQs3zVyaM' target="_blank">Product Video</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='mailto:support@docutize.ai'>Contact Us</a>
                  </li>
                  {/*<li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Book a Call</a>
                  </li>*/}
                  


                </ul>
              </div>

               

              {/* Col 3 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                  <h2 className="font-semibold text-[#0029ff]">USE CASES</h2>
                </div>
                <ul>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/accounting">Finance & Accounting</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/logistics">Logistics</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/business-development">Business Development</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/purchase">Purchase</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/business-documents">Business Documents</a>
                  </li>
                  {/*<li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/dpa">Full Service</a>
                  </li>*/}
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/convert-pdf-to-excel">PDF to Excel</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/convert-pdf-to-csv">PDF to CSV</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/convert-pdf-table">PDF Table</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="/bank-statement-converter">Bank Statement</a>
                  </li>
                  

                </ul>
              </div>

              {/* Col 4
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                  <h2 className="font-semibold text-[#0029ff]">CONVERTERS</h2>
                </div>
                <ul>
                <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://www.docutize.ai/faq'>PDF to Excel</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='/7-quick-tips'>PDF to CSV</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='/start-guide'>PDF OCR</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='/start-guide'>PDF Table</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://www.youtube.com/watch?v=vJsQs3zVyaM' target="_blank">Invoice</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='mailto:support@docutize.ai'>Bank Statement</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Account List</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Financial Statement</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Purchase Order</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Delivery Note</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Report</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Inventory List</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href='https://calendly.com/docutizeai/30min' target="_blank">Due Diligence</a>
                  </li>
                  


                </ul>
              </div>*/}

              {/* Col 5 */}
              <div className="text-slate-700 py-4 lg:w-1/7">
                <div>
                <h2 className="font-semibold text-[#0029ff]">COMPANY</h2>
                </div>
                <ul>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/about-us">About</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/terms-and-condition">Terms & Condition</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/privacy-policy">Privacy Policy</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/cookie-policy">Cookie Policy</a>
                  </li>
                  <li className="mt-4 text-[14px] text-slate-700 hover:text-[#0029ff] font-medium">
                    <a href="https://www.docutize.ai/dpa">DPA</a>
                  </li>
                </ul>
              </div>

            </div>
          </article>
          <div className='flex justify-between border-t-2 border-slate-200 m-8 py-5'>
            <p className='font-medium text-slate-600 text-[13px]'>© 2024 docutize.ai. All rights reserved.</p>
            <div className="icons flex justify-between gap-5">
            <a href="https://twitter.com/docutizeAI" target="_blank"><XIcon /></a>
            <a href="https://www.linkedin.com/company/docutize-ai" target="_blank"><LinkedInIcon /></a>
            <a href="https://www.youtube.com/@KoEu-we4of/featured" target="_blank"><YouTubeIcon /></a>
            </div>
          </div>
        </div>
      </article>
    </footer>
  );
};

export default Footer;
