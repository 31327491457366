import React from 'react';


import Inputsignup from "../../components/Inputsignin";
import Footer from "../../components/Footer";

import FAQshort from "../../components/FAQshort";
import Features from "../../components/Features";
import checkicon from '../../assets/check.svg'
import Logistics from '../../assets/Hero.png';
import user2 from '../../assets/Pers2.png';

const Logistic = () => {
  return (
    <section>
      
      
      <div className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl max-w-6xl  m-auto px-4 py-8">
      <div className="pb-5 border-b-2"><a href="/">
                  <img className="w-[150px]" src="/logo.svg" alt="logo" />
                  </a>
                  </div>
      <div className="mt-8 bullets ">
                                   <div>
                                   <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                            <h2 className="mb-[8px] xl:mb-[8px] lg:mb-[6px] md:mb-[2px] font-semibold tracking-wider text-[#0029ff] text-[16px] ">
            #1 EASIEST AI DATA EXTRACTION PLATFORM
          </h2>
                                <h2 className='font-medium text-[24px]'>
                                Optimize Logistics operations with AI Data Extraction
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Use AI to save time on extracting and organizing Text & Tabular Data from any Logistics document e.g.:
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Delivery Notes</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Purchase Orders</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Inventory Lists</span>
                                        </span>
                                    </li>
                        
                                </ul>
                            </div>
                            <div className='lg:w-[50%] '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Logistics} alt="logistics" />
                                </div>
                            </div>
                        </div>
                        <div className="xl:pt-20 pt-10"><Inputsignup /></div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                            "with docutize.ai we are able to save a lot of time when planning and tracking our logistics. It's really great as it is so easy to use compared to other tools and it's also a lot cheaper."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user2} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Jason E.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Logistics Manager                               
                        
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>

      <div className=" lg:pt-15 mb-12 ">
        <Features />
      </div>
      <div className="faq lg:pt-18 mb-12 ">
        <FAQshort />
      </div>
      <Footer />

      {/*<div className="bg-gradient-to-r from-teal-600 via-purple-800 to-pink-700 py-28 lg:pt-18 overflow-hidden">
        <TryAIGenerator />
      </div>*/}
    </section>
  );
};

export default Logistic;
