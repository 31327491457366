import React, { useEffect } from "react";
import Hero from "../components/Hero";

import TextLoop from "../components/TextLoop";
import UseCases from "../components/UseCases";

import FAQshort from "../components/FAQshort";
import Features from "../components/Features";
import HowItWorks from '../components/HowItWorks'

const LandingPage = () => {
  useEffect(() => {
    const useCasesSection = document.getElementById('usecases');
    const featureSection = document.getElementById('features');
    const pricingSection = document.getElementById('pricing');
    const howitworksSection = document.getElementById('howitworks');
    let offset = 400;
    if (window.innerWidth <= 425) {
      offset = 50; // Offset for screens below 425px width
    } else if (window.innerWidth > 425 && window.innerWidth < 768) {
      offset = 200; // Offset for screens between 425px and 768px width
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      offset = 170; // Offset for screens between 768px and 1024px width
    } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
      offset = 400; // Offset for screens above 1024px width
    }
    else if(window.innerWidth >= 1440){
      offset=500;
    }
  
    if (window.location.hash === '#usecases') {
      const useCasesRect = useCasesSection.getBoundingClientRect();
      const useCasesOffset = useCasesRect.top + window.scrollY + offset;
      window.scrollTo({ top: useCasesOffset, behavior: 'smooth' });
    } else if (window.location.hash === '#pricing') {
      const pricingRect = pricingSection.getBoundingClientRect();
      const pricingOffset = pricingRect.top + window.scrollY + offset;
      window.scrollTo({ top: pricingOffset, behavior: 'smooth' });
    } else if (window.location.hash === '#features') {
      const featureRect = featureSection.getBoundingClientRect();
      const featureOffset = featureRect.top + window.scrollY + offset;
      window.scrollTo({ top: featureOffset, behavior: 'smooth' });
    } else if (window.location.hash === '#howitworks') {
      const howitworksRect = howitworksSection.getBoundingClientRect();
      const howitworksOffset = howitworksRect.top + window.scrollY + offset;
      window.scrollTo({ top: howitworksOffset, behavior: 'smooth' });
    }
    
    
  }, []);

  return (
    <section>
      <main className=" pt-16 lg:pt-18  ">
        <Hero />
        {/*<TextLoop />*/}
      </main>

      <UseCases />

      <HowItWorks />
      <div className="py-16 lg:pt-18 mb-12 ">
        <Features />
      </div>
      <div className="faq lg:pt-18 mb-12 ">
        <FAQshort />
      </div>

      {/*<div className="bg-gradient-to-r from-teal-600 via-purple-800 to-pink-700 py-28 lg:pt-18 overflow-hidden">
        <TryAIGenerator />
      </div>*/}
    </section>
  );
};

export default LandingPage;
