import React from 'react'
import HowItWorksTabs from './HowItWorksTabs'

const HowItWorks = () => {
    return (
        <section id='howitworks' className='bg-[#F1F3FA] pb-5 lg:pb-20 pt-5 lg:pt-10'>
            <div className="xl:max-w-6xl lg:max-w-4xl md:max-w-2xl max-w-6xl  m-auto px-4 py-8">
                <h2 className="my-[8px] font-semibold text-center text-[#0029ff]  text-[16px]">
                    HOW IT WORKS
                </h2>
                <h1 className=' font-medium text-center text-slate-800  md:text-[24px]  text-[22px]'>Watch how you transform documents in 3 simple steps</h1>
                <HowItWorksTabs />
            </div>
        </section>
    )
}

export default HowItWorks
