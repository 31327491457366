import React, { useState } from 'react';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import user1 from '../assets/Pers1.png';
import user2 from '../assets/Pers2.png';
import user3 from '../assets/Pers3.png';
import user4 from '../assets/Pers4.png';
import user5 from '../assets/Pers5.png';
import Finance from '../assets/Finance.png';
import Logistics from '../assets/Logistics.png';
import Purchasing from '../assets/Purchasing.png';
import Business from '../assets/Business.png';
import other from '../assets/others.png';
// import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import checkicon from '../assets/check.svg'

const TabItem = ({ title, icon, activeTab, index, changeTab }) => {
    const isActive = activeTab === index;

    return (
        <button
            className={`${isActive ? 'bg-[#E8F0FF] rounded-xl' : 'bg-transparent'
                } flex justify-between items-center p-3 focus:outline-none`}
            onClick={() => changeTab(index)}
        >
            {icon && <span className={`${isActive ? 'fill-blue' : 'fill-none'
                } mr-2`}>{icon}</span>}
            <span className={`${isActive ? 'text-[#0029ff]' : 'border-transparent'
                } text-[16px] font-semibold `}>{title}</span>
        </button>
    );
};

const Tabs = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [menuItems, setMenuItems] = useState(false);
    const [selectedTabTitle, setSelectedTabTitle] = useState("Finance & Accounting");
    ///const [selectedTabIcon, setSelectedTabIcon] = useState(<MenuBookIcon />); ICON

    const changeTab = (index, title, icon) => {
        setActiveTab(index);
        setSelectedTabTitle(title);
        ///setSelectedTabIcon(icon); ICON
        setMenuItems(false);
    };

    const showDropdDownMenu = () => {
        setMenuItems(!menuItems);
    };


    return (
        <div className="tabs">
            {/* Dropdown menu for mobile */}
            <div className="lg:hidden mobile-tabs">
                <div
                    className="flex justify-between items-center relative p-3 border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    onClick={showDropdDownMenu}
                    value={activeTab}
                >
                    <span className='flex items-center'>
                        {/*<span> ICON
                            {selectedTabIcon}
                        </span>*/}
                        <span className='ml-2'>
                            {selectedTabTitle}
                        </span>
                    </span>
                    <ArrowDropDownIcon />
                </div>
                {menuItems &&
                    <div className=" flex-col bg-white border rounded-lg justify-between gap-3">
                        <TabItem title="Finance & Accounting" activeTab={activeTab} index={0} changeTab={(index, title) => changeTab(index, "Finance & Accounting")} />
                        <TabItem title="Logistics" activeTab={activeTab} index={1} changeTab={(index, title) => changeTab(index, "Logistics")} />
                        <TabItem title="Purchasing" activeTab={activeTab} index={2} changeTab={(index, title) => changeTab(index, "Purchasing")} />
                        <TabItem title="Business Development" activeTab={activeTab} index={3} changeTab={(index, title) => changeTab(index, "Business Development")} />
                        <TabItem title="Other Documents"  activeTab={activeTab} index={4} changeTab={(index, title) => changeTab(index, "Other Documents")} />
                        {/* TAB INCL ICON <TabItem title="Other Documents" icon={<StarBorderIcon />} activeTab={activeTab} index={4} changeTab={(index, title, icon) => changeTab(index, "Other Documents", <StarBorderIcon />)} />*/}
                    </div>
                }
            </div>

            {/* Tab items for desktop */}
            <div className="hidden lg:flex justify-between gap-3 border shadow-lg border-slate-300 rounded-xl p-1 ">
                <TabItem title="Finance & Accounting" activeTab={activeTab} index={0} changeTab={(index) => changeTab(index, "Finance & Accounting")} />
                <TabItem title="Logistics" activeTab={activeTab} index={1} changeTab={(index, title) => changeTab(index, "Logistics")} />
                <TabItem title="Purchasing" activeTab={activeTab} index={2} changeTab={(index, title) => changeTab(index, "Purchasing")} />
                <TabItem title="Business Development" activeTab={activeTab} index={3} changeTab={(index, title) => changeTab(index, "Business Development")} />
                <TabItem title="Other Documents" activeTab={activeTab} index={4} changeTab={(index, title) => changeTab(index, "Other Documents")} />
                 {/* TAB INCL ICON <TabItem title="Other Documents" icon={<StarBorderIcon />} activeTab={activeTab} index={4} changeTab={(index, title, icon) => changeTab(index, "Other Documents", <StarBorderIcon />)} />*/}
            </div>

            <div className="mt-8 bullets ">
                {activeTab === 0 &&
                    <div>
                        <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                                <h2 className='font-medium text-[24px]'>
                                Automate Data Extraction to simplify your financial workflows
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                    Use AI to save time on extracting and organizing Text & Tabular Data from any Finance & Accounting Document e.g.:
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />
                                        <span>
                                            <span className='font-medium'>Account Lists</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Bank Statements</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Financial Statements</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Purchase Orders</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Invoices</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className='lg:w-[50%] mt-0 '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Finance} alt="finance" />
                                </div>
                            </div>
                        </div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                            "docutize.ai makes our accounting work much faster and more accurate. It's the ideal tool as we can use it for almost all different documents."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user1} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Lisa B.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Accounting Manager
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeTab === 1 &&
                    <div>
                        <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                                <h2 className='font-medium text-[24px]'>
                                Optimize Logistics operations with AI Data Extraction
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Use AI to save time on extracting and organizing Text & Tabular Data from any Logistics document e.g.:
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Delivery Notes</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Purchase Orders</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Inventory Lists</span>
                                        </span>
                                    </li>
                        
                                </ul>
                            </div>
                            <div className='lg:w-[50%] '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Logistics} alt="logistics" />
                                </div>
                            </div>
                        </div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                            "with docutize.ai we are able to save a lot of time when planning and tracking our logistics. It's really great as it is so easy to use compared to other tools and it's also a lot cheaper."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user2} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Jason E.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Logistics Manager
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeTab === 2 &&
                    <div>
                        <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                                <h2 className='font-medium text-[24px]'>
                                Simplify procurement by extracting and organizing data
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Use AI to save time on extracting and organizing Text & Tabular Data from any Purchasing document e.g.:
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Purchase Orders</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Catalogs</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Delivery Notes</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Invoices</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Inventory Lists</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className='lg:w-[50%] '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Purchasing} alt="purchase" />
                                </div>
                            </div>
                        </div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                                "using docutize.ai made our purchasing process way faster and more advanced. It's great for gathering information that we would not get otherwise to make informed decisions."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user3} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Anna F.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Purchasing Manager
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeTab === 3 &&
                    <div>
                        <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                                <h2 className='font-medium text-[24px]'>
                                Fuel business growth by pinpointing opportunities through precise data extraction
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Use AI to save time on extracting and organizing Text & Tabular Data from any Business Development document e.g.:
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Market Research Reports</span> 
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Due Diligence</span> 
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Sales Proposals</span> 
                                        </span>
                                    </li>
                                   
                                </ul>
                            </div>
                            <div className='lg:w-[50%] '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={Business} alt="business" />
                                </div>
                            </div>
                        </div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                                "docutize.ai has transformed how we handle partner documents, speeding up our processes and enabling quicker, more informed decisions."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user4} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Henry T.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Business Development Manager
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeTab === 4 &&
                    <div>
                        <div className='mt-5 flex flex-col lg:flex-row justify-between items-center gap-10'>
                            <div className='text-[#2C374F] lg:w-[50%] pr-[10px]'>
                                <h2 className='font-medium text-[24px]'>
                                    Automate Data Extraction in your operations
                                </h2>
                                <p className='font-normal mt-3 text-[16px]'>
                                Use AI to save time on extracting and organizing Text & Tabular Data from any Business document e.g.:
                                </p>
                                <ul className='font-normal mt-3 text-[16px]'>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Reports</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Tabular Data</span>
                                        </span>
                                    </li>
                                    <li className='flex gap-2 items-center'>
                                        <img src={checkicon} alt="" />

                                        <span>
                                            <span className='font-medium'>Contracts</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className='lg:w-[50%] '>
                                <div className="bg-[#0039FF] frame-container shadow-xl border border-slate-200 p-2 rounded-xl">
                                    <img className='w-[100%]' src={other} alt="other" />
                                </div>
                            </div>
                        </div>
                        <div className="feedback lg:w-[50%] mt-10 mx-auto">
                            <p className='text-justify'>
                                "docutize.ai has been crucial in gathering report data, allowing our quality managers to analyze information more effectively and enhance our standards."
                            </p>
                            <div className="mt-5 flex justify-center">
                                <img className='w-[40px] h-[40px] rounded-full' src={user5} alt="user" />
                                <div className="ml-[10px]">
                                    <h6 className='font-medium text-[14px]'>
                                        Maria E.
                                    </h6>
                                    <span className='font-medium text-[13px]'>
                                        Quality Manager
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Tabs;
